import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from './auth.service';
import { SessionStorageService } from './core/util/session-storage.service';
import { NotificationService } from './core/components/notification/notification.service';


@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    constructor(
        private authService: AuthService,
        private router: Router) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean {
        if (this.authService.getIsAuthenticated().refreshToken != null) {
            return true; // Allow access to the route for users
        } else {
            this.router.navigate(['/login']); // Redirect to an login page
            return false; // Do not allow access for unidentified users
        }
    }
}


@Injectable({
    providedIn: 'root'
})
export class LoginGuard implements CanActivate {
    constructor(
        private authService: AuthService) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean {
        if (this.authService.getIsAuthenticated().refreshToken === null) {
            return true;
        } else {
            return false;
        }
    }
}

@Injectable({
    providedIn: 'root'
})
export class CostingGuard implements CanActivate {
    constructor(
        private authService: AuthService,
        private sessionStorageService: SessionStorageService,
        private notificationService: NotificationService,
    ) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): boolean {
        let userRoles = this.sessionStorageService.getUserRoles()
        if (!userRoles) {
            this.notificationService.Warning("This action is prohibited for you by the system.", "Unauthorized access")
            return false;
        }

        let costingRole = userRoles.roles.find((item: any) => item.role === 'costing')

        if (costingRole) {
            return true;
        } else {
            this.notificationService.Warning("This action is prohibited for you by the system.", "Unauthorized access")
            return false;
        }
    }
}

@Injectable({
    providedIn: 'root'
})
export class MgtAdminGuard implements CanActivate {
    constructor(
        private authService: AuthService,
        private sessionStorageService: SessionStorageService,
        private notificationService: NotificationService,
    ) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean {
        let userRoles = this.sessionStorageService.getUserRoles()
        if (!userRoles) {
            this.notificationService.Warning("This action is prohibited for you by the system.", "Unauthorized access")
            return false;
        }
        let mgtAdminRole = userRoles.roles.find((item: any) => item.role === 'mgt_admin')

        if (mgtAdminRole) {
            return true;
        } else {
            this.notificationService.Warning("This action is prohibited for you by the system.", "Unauthorized access")
            return false;
        }
    }
}